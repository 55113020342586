// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "./flatpickr_controller";


// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  // all flatpickr hooks are available as callbacks in your Stimulus controller
  valueUpdate(selectedDates, dateStr, instance) {
    console.log('valueUpdate', arguments)
    console.log(instance.selectedDates)
    console.log(instance.config)
  }

  change(selectedDates, dateStr, instance) {
    console.log('change', arguments)
  }
}
