import { Controller } from "stimulus"
import TomSelect from "tom-select";

const REGEX_EMAIL = '([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@' +
                '(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';

export default class extends Controller {
  static targets = ['searchField']

  connect() {
    this.enableTS();
    $(this.element).tooltip({
      container: this.element,
      selector: 'div.not-found, div.existing'
    })
  }

  enableTS() {
    const autoload = this.data.has('autoload');

    let emails = [];
    if (this.data.has('emails')) {
      emails = JSON.parse(this.data.get('emails'));
    }

    const select = new TomSelect(this.searchFieldTarget,
      {
        plugins: ['remove_button'],
        maxItems: null,
        preload: autoload,
        splitOn: /\s*[,;\s]+\s*/,
        valueField: 'email',
        labelField: 'name',
        openOnFocus: autoload,
        closeAfterSelect: true,
        searchField: ['name', 'organizationName', 'email'],
        sortField: [
          {field: 'name', direction: 'asc'},
          {field: 'email', direction: 'asc'},
          {field: 'organizationName', direction: 'asc'}
        ],
        onInitialize: function() {
          emails.forEach(e => this.createItem(e))
        },
        load: (query, callback) => {
          const url = this.data.get('url') + '?term=' + encodeURIComponent(query);
          fetch(url)
            .then(response => response.json())
            .then(json => {
              callback(json);
            })

        },
        render: {
          item: function(item, escape) {
            const klass = item.notFound ? 'not-found' : item.isMember ? 'existing' : '';
            const title = item.notFound ? 'User Not Found' : item.isMember ? 'Already a member' : '';
            const content = '<div class="' + klass + '" title="' + title + '">' +
              (item.name ? escape(item.name) : '') +
              (item.email ? '<span class="email">' + escape('<' + item.email + '>') + '</span>' : '') +
            '</div>';

            return content
          },
          option: function(item, escape) {
            var label = escape(item.name) + '<span class="email">' + escape('<' + item.email + '>') + '</span>';
            return '<div>' +
              '<span class="label">' + label + '</span>' +
              '<span class="caption">' + escape(item.organizationName) + '</span>' +
            '</div>';
          }
        },
        createFilter: function(input) {
          var regexpA = new RegExp('^' + REGEX_EMAIL + '$', 'i');
          var regexpB = new RegExp('^([^<]*)\<' + REGEX_EMAIL + '\>$', 'i');
          return regexpA.test(input) || regexpB.test(input);
        },
        create: async (input, callback) => {
          console.log('create', input)
          const url = this.data.get('url') + '?email=' + encodeURIComponent(input);
          let user = await fetch(url)
            .then(response => response.json())
            .then(json => json[0])
          if (user === undefined) user = this.parseEmail(input);
          console.log('create user', user)
          return await callback(user)
        }
      });

    // A hack to remove persisted tooltip on delete
    select.on('item_remove', () => {
      $('.tooltip', this.element).remove()
    });
  }

  parseEmail(input) {
    if ((new RegExp('^' + REGEX_EMAIL + '$', 'i')).test(input)) {
      return {email: input, notFound: true};
    }
    const match = input.match(new RegExp('^([^<]*)\<' + REGEX_EMAIL + '\>$', 'i'));
    if (match) {
      const email = match[2],
            name = match[1].trim();

      return {
        email,
        name,
        notFound: true
      };
    }

    console.error('Invalid email address.');
    return false;
  }
}
