<template>
  <tooltip
    custom-class="tooltip-danger"
    :placement="params.errorPlacement || 'right'"
    :enable="params.value.error"
    :text="params.errorMsg(params)"
    v-if="params.valueFormatted"
  >
    <span class="decrement" :class="{'danger': params.value.error}" @click="applyToBid">{{ params.valueFormatted }}</span>
  </tooltip>
</template>


<script>
  export default {
    methods: {
      applyToBid() {
        this.params.node.setDataValue('bid', this.params.value.newBid);
      }
    }
  }
</script>
