<template>
  <div>
    <table class="table clickable-rows">
      <thead class="hidden">
        <tr>
          <td colspan="3">Date</td>
          <td>Response</td>
          <td>User</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(message, index) in messages" @click="toggleOpen(index)">
          <td class="nowrap" width=100>{{message.date}}</td>
          <td width=36 class="nowrap">
            <span style="background: #f5f5f5; border-radius: 8px; padding: 0px 6px; color: #666" v-if="message.attachmentCount > 0">
              <i class="fal fa-paperclip"></i> {{message.attachmentCount}}
            </span>
          </td>
          <td width=36 style="text-align: right" class="nowrap">
            <i class="fas fa-flag-alt fa-fw icon-flagged" v-if="message.flagged"></i>
            <span :class="{'badge': true, 'badge-success': (message.remainingTasks == 0), 'badge-warning': (message.remainingTasks > 0), 'badge-number': true}"><span class="name">{{message.remainingTasks}}</span></span>
          </td>
          <td>
            <div class="truncate-ellipsis">
              <div>
                <a :href="message.responsePath" @click.prevent>
                  {{message.memberName}}
                  <tooltip
                    text="Suspended Bidder"
                    custom-class="tooltip-suspended"
                    v-if="message.suspended"
                  >
                    <i class="fas fa-user-slash" style="color: Tomato" />
                  </tooltip>
                  <small>{{message.documentName}}</small>
                </a>
              </div>
            </div>
          </td>
          <td>
            <div class="truncate-ellipsis">
              <span>{{message.respondableName}}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <modal v-model="open" size="md" :footer="false" v-if="open" class="inbox">
      <template slot="header">
        <div class="modal-loading" v-if="childLoadedCount < 2">
          <span>Loading&hellip;</span>
        </div>
        <div class="pull-right">
          <div class="btn-group">
            <btn size="lg" @click="previous" :disabled="!hasPrevious"><i class="fa fa-arrow-left"></i></btn>
            <btn size="lg" @click="next" :disabled="!hasNext"><i class="fa fa-arrow-right"></i></btn>
          </div>
          <btn size="lg" @click="open=false"><i class="fa fa-times"></i><span class="hidden-xs"> Close</span></btn>
        </div>
        <div class="pull-right" style="padding: 14px 14px 0 0; font-size: .9em; color: #333;">{{index + 1}} of {{messages.length}}</div>
        <a :href="response.documentPath" class="btn btn-lg btn-default hidden-xs">View Document</a>
        <a :href="response.responsePath" class="btn btn-lg btn-default">View Response</a>
      </template>
      <div class="action_column">
        <section class="action_header">
          <header>
            <div class="subtitle pull-right">{{response.date}}</div>
            <h2>
              {{response.memberName}}
              <small>{{response.respondableName}}</small>
              <tooltip
                text="Suspended Bidder"
                v-if="response.suspended"
                custom-class="tooltip-suspended"
              >
                <i class="fas fa-user-slash" style="color: Tomato" />
              </tooltip>
            </h2>
            <div class="subtitle">{{response.documentName}} Response</div>
          </header>
        </section>
        <upload-list
          :url="uploadsPath"
          :downloadPath="response.downloadPath"
          :key="uploadsPath"
          :sealed="response.sealed"
          :peek="response.peek"
          @loaded="childLoaded"
        />
        <task-list
          :read-only="true"
          :user-id="response.respondableId"
          :base-url="response.documentPath"
          :key="response.responsePath"
          @loaded="childLoaded"
        />
      </div>
    </modal>
  </div>
</template>

<script>
  import axios from '../mixins/axiosDefaults'
  import { EventBus } from '../mixins/events.js'
  import { inbox } from '../mixins/inbox'
  import TaskList from './TaskList.vue'
  import UploadList from './UploadList.vue'
  import { Tooltip } from 'uiv'

  export default {
    components: { TaskList, Tooltip, UploadList },
    mixins: [inbox],
    data: function () {
      return {
        response: {},
        loaded: false,
        childLoadedCount: 0
      }
    },
    created() {
      this.fetchData();
    },
    computed: {
      uploadsPath() {
        return this.response.responsePath + '/attachments'
      }
    },
    methods: {
      fetchData() {
        axios.get(this.url).then(this.loadData)
      },
      loadData(response) {
        this.messages = response.data;
        this.loaded = true;
      },
      toggleOpen(index) {
        this.childLoadedCount = 0;
        this.index = index;
        this.response = this.messages[index];
        this.open = true;
      },
      childLoaded() {
        this.childLoadedCount++
      }
    }
  }
</script>

<style lang="scss">
  .modal-loading {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    span {
      display: inline-block;
      background: #fff;
      padding: 6px 10px;
      font-size: 1.1em;
      font-weight: bold;
      box-shadow: 0 0 4px #999;
      background: #F2D887;
      color: #664A1B;
    }
  }
  .modal-body header h2 small{
    color: #f4e1c1;
    font-size: .75em;
    font-weight: normal;
  }
  .inbox .modal-body{
    padding: 10px;
    background: #f5f5f5;
  }
  .clickable-rows tr:hover td {
    cursor: pointer;
    a {
      text-decoration: underline;
    }
  }

</style>
