<template>
  <span>
    <span v-if="params.value">
      <div class="pull-right" v-if="params.clicked">
        <i class="fas fa-times-circle" @click.stop="clicked" />
      </div>
      <span class="ag-value-change-delta" :class="classObject">
        {{ arrow }}{{ params.valueFormatted }}
      </span>
    </span>

    <span v-else class="add-delta" @click.stop="edit">{{ arrow }}</span>
  </span>
</template>

<script>
  export default {
    computed: {
      arrow() {
        let up = this.params.context.forward;
        if (up) {
          if (this.params.value && this.params.value < 0) up = false;
        } else {
          if (this.params.value && this.params.value < 0) up = true;
        }
        return (up) ? '↑' : '↓'
      },
      classObject() {
        return {
          'ag-value-change-delta-down': this.params.value < 0,
          'ag-value-change-delta-up':  this.params.value > 0
        }
      }
    },
    methods: {
      clicked(event) {
        this.params.clicked(this.params, event)
      },
      edit(e) {
        if (this.params.node.rowPinned) return false;

        this.params.api.startEditingCell({
          rowIndex: this.params.rowIndex,
          colKey: this.params.column.colId
        });
      }
    }
  }
</script>
