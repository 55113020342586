// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "./flatpickr_controller";


// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    if (this.sendReminderAt) {
      this.sendReminderAtFlatpickr.set('maxDate', selectedDates[0]);
      if (selectedDates[0]) {
        // Set to previous day at 8am
        let newReminderDate = new Date(selectedDates[0].getTime());
        newReminderDate.setDate(selectedDates[0].getDate() - 1);
        newReminderDate.setHours(8);
        newReminderDate.setMinutes(0);
        newReminderDate.setMilliseconds(0);
        this.sendReminderAtFlatpickr.setDate(newReminderDate);
      }
    }
  }

  get sendReminderAt() {
    return document.querySelector("#document_package_send_reminder_at");
  }

  get sendReminderAtFlatpickr() {
    return this.sendReminderAt._flatpickr;
  }
}
