<template>
  <section id="tasks">
    <header v-bind:class="{'warning': incomplete}">
      <div class="pull-right subtitle" style="margin-top: 3px" v-if="loaded">{{remaining}} Remaining</div>
      <h2>Tasks</h2>
    </header>
    <div class="task_list">
      <div class="form-child" v-for="(task, i) in tasks" v-bind:class="{'complete': task.completed, 'incomplete': !task.completed, 'flagged': task.flagged}" :key="task.id">
        <div class="child-action">
          <tooltip :text="completedAt(task)" placement="top" :enable="task.completed">
            <i
              class="far fa-lg icon-btn"
              :class="{
                'fa-square': !task.completed,
                'fa-check-square': task.completed,
                'locked': locked

              }"
              v-on:click="toggle(task)"
            />
          </tooltip>
        </div>
        <div class="child-content">
          <div class="flags" v-if="task.flagged || (task.completed && !locked && canFlag)">
            <tooltip :text="flagTooltip(task)" placement="top">
              <i
                class="fas fa-flag-alt icon-btn"
                :class="{
                  'icon-flagged': task.flagged,
                  'icon-flag': !task.flagged,
                  'unlocked': (!locked && canFlag)
                }"
                v-on:click="toggleFlag(task)"
              />
            </tooltip>
          </div>
          <div class="document-body" v-html="task.description" @click.prevent=externalContentClick></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import { EventBus } from '../mixins/events.js'
  import axios from '../mixins/axiosDefaults'
  import { Tooltip } from 'uiv'

  export default {
    props: {
      userId: {
        type: Number,
        required: true
      },
      baseUrl: {
        type: String,
        required: true
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {
      Tooltip
    },
    data: function () {
      return {
        tasks: [],
        unlocked: false,
        canFlag: false,
        loaded: false
      }
    },
    computed: {
      remaining() {
        let remainingCount = this.tasks.filter(task => !task.completed || task.flagged).length;
        this.$emit('is-complete', remainingCount == 0)
        return remainingCount;
      },
      incomplete(){
        return this.loaded && this.remaining > 0;
      },
      locked() {
        return this.readOnly || !this.unlocked;
      }
    },
    created() {
      this.refreshPerms();
      this.loadTasks();
    },
    methods: {
      completedAt(task) {
        let date = task.completedAt;
        if (date) {
          return `Completed ${moment(date).format('ddd MMM Do, YYYY [at] h:mm:ss A')}`
        }
      },
      flagTooltip(task){
        if (task.flagged){
          return (this.canFlag) ? 'Flagged as incomplete. Click to unflag.' : 'Flagged as incomplete. Please contact us for further information.';
        } else {
          return 'This task has been marked complete. Click to flag if it still needs attention.'
        }
      },
      // Check if permissions changed at any time - mainly on error, assuming state changed
      refreshPerms() {
        axios.get(this.baseUrl + '/responses/' + this.userId + '.json')
          .then(response => {
            this.unlocked = response.data.unlocked;
            this.canFlag = response.data.canFlag;
          });
      },
      loadTasks() {
        axios.get(this.baseUrl + '/tasks?response_id=' + this.userId)
          .then(response => {
            this.tasks = response.data;
            this.loaded = true;
            this.$emit('loaded');
          })
          .catch(this.requestErrorHandler);
      },
      toggle(task) {
        if (!this.locked) {
          let action = (task.completed) ? 'incomplete' : 'complete';
          this.updateTask(task, action);
        }
      },
      toggleFlag(task) {
        if (!this.locked && this.canFlag) {
          let action = (task.flagged) ? 'unflag' : 'flag';
          this.updateTask(task, action);
        }
      },
      updateTask(task, action) {
        axios.post(this.baseUrl + '/tasks/' + task.id + '/' + action + '?response_id=' + this.userId)
          .then(response => {
            task.completed = response.data.completed;
            task.flagged = response.data.flagged;
            if (task.flagged) EventBus.$emit('taskFlagged');
          })
          .catch(this.requestErrorHandler);
      },
      requestErrorHandler(error) {
        if(error.request.status === 403) {
          console.log(error)
          this.refreshPerms();
        } else if (error.request.status !== 0) {
          console.log(error.request.status);
          console.log(error.request.responseText);
          alert('Something has gone wrong. Please contact support if it continues.')
        }
      },
      externalContentClick(event) {
        let { target } = event;
        while (target && target.tagName !== 'A') target = target.parentNode;
        window.open(target.href, '_blank');
      }
    }
  }
</script>

<style lang="scss" scoped>
  i.locked {
    cursor: not-allowed;
  }

  .fa-square {
    color: #999;
    background: #fff;
  }

  .fa-check-square {
    color: #555;
    background: #fff;
  }

  .icon-flag, .icon-flagged {
    font-size: 1.2em
  }
</style>
