import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import ToggleButton from 'vue-js-toggle-button'

export default class extends Controller {
  static targets = ["reveal", "setStage", "addTo", "input", "pseudonym", "gates", "gateButton", "pseudonymToggle", "eventSearch"];

  connect() {
    this.toggleToolbar();
    this.setupToggle();

    // Actually disable addTo button
    this.addToTarget.addEventListener('click', e => {
      if (this.addToTarget.hasAttribute('disabled')) e.stopPropagation();
    })
  }

  disableLowerStages() {
    const stages = ["pending", "identified", "prequalified", "proposer", "shortlisted", "awarded"].reverse();

    const checkedGates = [];
    this.gatesTargets.forEach(checkbox => {
      if (checkbox.checked) {
        checkedGates.push(stages.indexOf(checkbox.value))
      }
    })
    if (checkedGates.length > 0) {
      const min = Math.min(...checkedGates);
      this.gateButtonTargets.forEach((el, i) => {
        el.classList.toggle("disabled", i > min)
        const icon = el.querySelector('.circle-icon');
        icon.classList.toggle("fas", i > min)
        icon.classList.toggle("far", i <= min)
      })
    }
  }

  toggleToolbar() {
    let checkedCount = this.inputTargets.filter(e => e.checked).length;
    if (checkedCount === 0) {
      this.setStageTarget.setAttribute("disabled", "disabled");
      this.addToTarget.setAttribute("disabled", "disabled");
    } else {
      this.setStageTarget.removeAttribute("disabled");
      this.addToTarget.removeAttribute("disabled");
    }
  }

  setupToggle() {
    if (this.hasPseudonymToggleTarget) {
      const el = this.pseudonymToggleTarget;
      let reveal = false;
      const app = new Vue({
        el,
        data: {
          checked: false
        },
        template: '<toggle-button v-model="checked" :sync="true" @change="toggle" :width="72" :labels="{checked: \'REVEAL\', unchecked: \'REVEAL\'}"></toggle-button">',
        watch: {
          checked: checked => {
            this.toggleReveal(checked)
          }
        },
        methods: {
          toggle(val)  {
            let reveal = val.value;
            if (reveal && !window.confirm("Are you sure you want to see bidder pseudonyms?")) {
              this.checked = false;
            }
          }
        }
      });
    }
  }

  toggleReveal(reveal) {
    this.pseudonymTargets.forEach(e => e.classList.toggle("hidden", !reveal));
  }

  submit(event) {
    event.preventDefault();
    const eventId = this.eventSearchTarget.value;
    const emails = this.inputTargets.filter(e => e.checked).map(e => e.dataset['email'])
    window.location = `/events/${eventId}/members/new?` + this.parameterizeArray('emails', emails);
  }

  parameterizeArray(key, arr) {
    arr = arr.map(encodeURIComponent)
    return key+'[]=' + arr.join('&'+key+'[]=')
  }
}
