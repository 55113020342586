import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $('#user_country')
      .select2()
      .change(function(e) {
        if ($(this).val() === 'US') {
          $('#user_state').prop('disabled', false)
          $('#user_state_alt').prop('disabled', true).val('')
          $('#state').show()
          $('#state_alt').hide()
        } else {
          $('#user_state').prop('disabled', true).val('');
          $('#user_state_alt').prop('disabled', false);
          $('#state').hide();
          $('#state_alt').show();
        }
      })
      .trigger('change');

    $('#user_state').select2();

    if ($('#user_time_zone').val() === '') {
      console.log('no time zone!')
      $('#user_time_zone').selectTimeZone();
    }
    $('#user_time_zone').select2();
  }

  disconnect() {
    $('#user_country').select2('destroy');
    $('#user_state').select2('destroy');
    $('#user_time_zone').select2('destroy');
  }
}
