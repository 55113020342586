// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

// you can also import a translation file
// import { French } from "flatpickr/dist/l10n/fr.js";

import 'flatpickr/dist/plugins/confirmDate/confirmDate.css';
import 'flatpickr/dist/flatpickr.css';


// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    let defaultDate = this.element.dataset.date;
    if (defaultDate === 'null') defaultDate = null;
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      // allowInput: true,
      altFormat: 'M j, Y at h:i K',
      altInput: true,
      defaultDate: defaultDate,
      enableTime: true,
      minuteIncrement: 15,
      plugins: [
        new confirmDatePlugin({
          confirmIcon: "<i class='fas fa-check' style='padding-left: 10px'></i>", // your icon's html, if you wish to override
          confirmText: "Done",
          showAlways: true
        })
      ]
    };
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log("the callback returns the selected dates", selectedDates);
  //   console.log("but returns it also as a string", dateStr);
  //   console.log("and the flatpickr instance", instance);
  // }
}
