<template>
  <popover
    title="Weighting Formula"
    trigger="hover"
    :enterable="false"
    :show-delay="400"
    v-if="params.data.percentage || params.data.adjustment"
  >
    <i class="far fa-function" data-role="trigger" />

    <template slot="popover">
      <table>
        <tbody>
          <tr v-if="placed">
            <th>Bid</th>
            <td>{{ placed | currency(locale, currency, precision) }}</td>
          </tr>

          <tr v-if="params.data.percentage">
            <th>Weighting</th>
            <td>{{params.data.percentage | percent(locale, precision) }}</td>
          </tr>

          <tr v-if="params.data.adjustment">
            <th>Adjustment</th>
            <td>{{ params.data.adjustment | currency(locale, currency, precision) }}</td>
          </tr>

          <tr class="trfoot" v-if="params.value">
            <th>Weighted Bid</th>
            <td>{{ params.value | currency(locale, currency, precision) }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </popover>
</template>

<script>
  export default {
    computed: {
      locale() {
        return this.params.context.locale;
      },
      currency() {
        return this.params.context.currency;
      },
      placed() {
        return this.params.api.getValue('placed', this.params.node)
      },
      precision() {
        return this.params.data.precision
      }
    },
    filters: {
      currency(value, locale, currency, precision) {
        return new Intl.NumberFormat(locale, {
          currency,
          style: 'currency',
          minimumFractionDigits: precision
        }).format(value)
      },
      percent(value, locale, precision) {
        return new Intl.NumberFormat(locale, {
          style: 'percent',
          minimumFractionDigits: precision
        }).format(value)
      }
    }
  }
</script>
