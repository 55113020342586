var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"tasks"}},[_c('header',{class:{'warning': _vm.incomplete}},[(_vm.loaded)?_c('div',{staticClass:"pull-right subtitle",staticStyle:{"margin-top":"3px"}},[_vm._v(_vm._s(_vm.remaining)+" Remaining")]):_vm._e(),_vm._v(" "),_c('h2',[_vm._v("Tasks")])]),_vm._v(" "),_c('div',{staticClass:"task_list"},_vm._l((_vm.tasks),function(task,i){return _c('div',{key:task.id,staticClass:"form-child",class:{'complete': task.completed, 'incomplete': !task.completed, 'flagged': task.flagged}},[_c('div',{staticClass:"child-action"},[_c('tooltip',{attrs:{"text":_vm.completedAt(task),"placement":"top","enable":task.completed}},[_c('i',{staticClass:"far fa-lg icon-btn",class:{
              'fa-square': !task.completed,
              'fa-check-square': task.completed,
              'locked': _vm.locked

            },on:{"click":function($event){return _vm.toggle(task)}}})])],1),_vm._v(" "),_c('div',{staticClass:"child-content"},[(task.flagged || (task.completed && !_vm.locked && _vm.canFlag))?_c('div',{staticClass:"flags"},[_c('tooltip',{attrs:{"text":_vm.flagTooltip(task),"placement":"top"}},[_c('i',{staticClass:"fas fa-flag-alt icon-btn",class:{
                'icon-flagged': task.flagged,
                'icon-flag': !task.flagged,
                'unlocked': (!_vm.locked && _vm.canFlag)
              },on:{"click":function($event){return _vm.toggleFlag(task)}}})])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"document-body",domProps:{"innerHTML":_vm._s(task.description)},on:{"click":function($event){$event.preventDefault();return _vm.externalContentClick($event)}}})])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }