<template>
  <section id="uploads">
    <header>
      <nav v-if="attachments.length > 1">
        <a :href="downloadPath" class="btn btn-default">
          <i class="fas fa-download"></i> Download All
        </a>
      </nav>
      <div class="subtitle pull-right" v-if="loaded" style="margin-top: 3px">{{attachments.length}} {{(attachments.length == 1) ? 'File' : 'Files'}}</div>
      <h2>
        Uploads
        <i class="fas fa-shield-check" title="Sealed Response" v-if="sealed"></i>
      </h2>
    </header>
    <table class="table" v-if="loaded">
      <thead class="hidden">
        <td></td>
        <td>File name</td>
        <td class="hidden-xs hidden-sm hidden-md numeric">Size</td>
        <td class="hidden-xs hidden-sm hidden-md numeric">Added</td>
      </thead>
      <tbody style="background: #fff">
        <tr v-for="(attachment, i) in attachments" :key="attachment.id" @mouseover="hoveredRow = i" @mouseleave="hoveredRow = null">
          <td class="metadata" width=18>
            <i class="fal file-icon fa-lg fa-fw" :class="[(sealed && i == hoveredRow) ? 'fa-shield-check' : attachment.icon]"></i>
          </td>
          <td>
            <a :href="attachment.path" @click="checkSealed">{{attachment.name}}</a>
          </td>
          <td class="numeric nowrap">
            {{attachment.fileSize}}
          </td>
          <td class="numeric nowrap">
            <tooltip :text="createdAt(attachment)" placement="left">
              <span>{{attachment.date}}</span>
            </tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
  import { EventBus } from '../mixins/events.js'
  import axios from '../mixins/axiosDefaults'
  import { Tooltip } from 'uiv'

  export default {
    props: {
      url: {
        type: String,
        required: true
      },
      downloadPath: {
        type: String,
        required: false
      },
      sealed: {
        type: Boolean,
        required: false,
        default: false
      },
      peek: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {
      Tooltip
    },
    data: function () {
      return {
        attachments: [],
        loaded: false,
        hoveredRow: null
      }
    },
    created() {
      this.loadAttachments();
    },
    methods: {
      loadAttachments() {
        axios.get(this.url)
          .then(response => {
            this.attachments = response.data;
            this.loaded = true;
            this.$emit('loaded');
          })
          .catch(this.requestErrorHandler);
      },
      requestErrorHandler(error) {
        if(error.request.status === 403) {
          console.log(error)
          this.refreshPerms();
        } else if (error.request.status !== 0) {
          console.log(error.request.status);
          console.log(error.request.responseText);
          alert('Something has gone wrong. Please contact support if it continues.')
        }
      },
      createdAt(attachment) {
        return moment(attachment.createdAt).format('ddd MMM Do, YYYY [at] h:mm:ss A')
      },
      checkSealed(e){
        if (this.sealed && !this.peek) {
          e.preventDefault();
          alert('This response is sealed. Uploads are only available after the due date has passed.');
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
