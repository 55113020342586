import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "pricing", "scoring", "extensions", "leadBid" ]

  connect() {
    $('#lot_category_ids').select2({
      width: '100%'
    });
  }

  disconnect() {
    $('#lot_category_ids').select2('destroy');
  }

  toggleRank(event) {
    let priceRanking = event.target.value == 'price';
    this.pricingTarget.classList.toggle('hidden', !priceRanking)
    this.scoringTarget.classList.toggle('hidden', priceRanking)
  }

  toggleExtensions(event) {
    this.extensionsTarget.classList.toggle('hidden', !event.target.checked)
  }

  toggleLeadBid(event) {
    const forced = event.target.value === 'english';
    this.leadBidTarget.disabled = forced;
    this.leadBidTarget.checked = forced;
  }
}
