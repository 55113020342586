<template>
  <div :class="state">
    <template v-if="state === 'paused'">
      <div class="icon"><i class="fas fa-pause-circle fa-5x" /></div>
      <h2>Timer Paused</h2>
      <p class="message">This lot is not accepting bids at this time. Please wait while we address the issue - it is not a technical problem. If action is required, a bid manager will be in touch. Otherwise, bidding will resume soon.</p>
    </template>

    <template v-else>
      <h2>Loading, Please Wait&hellip;</h2>
    </template>
  </div>
</template>

<script>
  export default {
    computed: {
      state() {
        return this.params.state();
      }
    }
  }
</script>


<style lang="scss" scoped>
  h2 {
    color: #555;
    font-size: 1.5em;
    font-weight: normal;
    .fa {
      color: #d0d0d0;
    }
  }

  p {
    color: #333;
  }

  .paused {
    background: #fff;
    width: 540px;
    padding: 36px 18px;
    border-radius: 8px;
    box-shadow: -1px 1px 5px rgba(0,0,0, 0.25);

    .icon {
      color: #777;
      color: #9932CC;
      margin-bottom: 12px;
    }
    h2 {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: .5em;
    }
    p {
      font-size: 1.2em;
      line-height: 1.5em;
      text-align: left;
    }
  }
</style>
