import { Controller } from "stimulus"
import TomSelect from 'tom-select'

export default class extends Controller {
  static targets = ['eventSearch', 'stages']

  static values = {
    ignoreId: Number
  }

  connect() {
    const select = new TomSelect(this.eventSearchTarget,
      {
        valueField: 'id',
        labelField: 'name',
        preload: true,
        maxOptions: 100,
        maxItems: 1,
        openOnFocus: true,
        closeAfterSelect: true,
        searchField: ['name', 'client'],
        sortField: [
          {field: 'closeDate', direction: 'asc'},
          {field: 'client', direction: 'asc'},
          {field: 'name', direction: 'asc'}
        ],
        optgroups: [
          {value: 'current', label: 'Current'},
          {value: 'past', label: 'Past'}
        ],
        optgroupField: 'age',
        lockOptgroupOrder: true,
        onDropdownClose: function(e) {
          select.blur();
        },
        load: (query, callback) => {
          const url = `/events/search.json?editor=1&ignore_id=${encodeURIComponent(this.ignoreIdValue)}`;
          fetch(url)
            .then(response => response.json())
            .then(json => {
              callback(json);
              select.settings.load = null;
            })
        },
        render: {
          item: (item, escape) => `<div>${escape(item.name)} <span class="email">${escape(item.client)}</span></div>`,
          option: function(item, escape) {
            const date = moment(item.closeDate).format('ll');
            return '<div>' +
              '<span class="label">' + escape(item.name) + ' <span class="badge">' + date + '</span></span>' +
              '<span class="caption">' + escape(item.client) + '</span>' +
            '</div>';
          },
          no_results:function(data,escape){
            return '<div class="no-results">No results found for "'+escape(data.input)+'"</div>';
          },
        }
      }
    )
  }

  toggleRole(event) {
    const team = event.target.value == 'team';
    this.stagesTarget.classList.toggle('hidden', team)
  }
}

