import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["reminderField", "attachment"]

  connect() {
    $('#attachments').tooltip({ selector: '.fa-file-icon' });

    let searchUrl = this.data.get('url');
    $("#document_package_event_id").select2({
      minimumInputLength: 1,
      ajax: {
        url: searchUrl,
        dataType: 'json',
        delay: 250,
        results: function (data, page) {
          return {results: data};
        },
        processResults: function (data, params) {
          return {
            results: _.map(data, function(event){
              return {id: event.id, text: event.name + ' - ' + event.client};
            })
          }
        }
      }
    });
  }

  toggleReminder(event) {
    this.reminderFieldTarget.classList.toggle('hidden', !event.target.checked);
    if (!event.target.checked) document.querySelector("#document_package_send_reminder_at")._flatpickr.clear();
  }

  selectAll() {
    this.attachmentTargets.forEach(t => t.checked = true)
  }

  selectNone() {
    this.attachmentTargets.forEach(t => t.checked = false)
  }
}
