<template>
  <span>
    <div class="edit-bid pull-right">
      <i class="fas fa-pencil" @click.stop="edit" />
    </div>
    <tooltip
      text="Tie Bids Disallowed"
      custom-class="tooltip-danger"
      placement="top"
      :enable="invalidTie"
    >
      <span class="bid" :class="{danger: error}">
        <template v-if="typeof params.valueFormatted === 'string'">
          {{ params.valueFormatted }}
        </template>

        <span class="bid-required" v-else-if="params.data.bid_required === true">Required</span>
      </span>
    </tooltip>
  </span>
</template>

<script>
  export default {
    computed: {
      error() {
        const { value } = this.params;
        // return typeof value === 'string' || (this.params.data.errors && (value === null || value === undefined))
        return typeof value === 'string' || this.params.data.errors
      },
      invalidTie() {
        const { errors } = this.params.data
        return (errors && errors.ties) === true
      }
    },
    methods: {
      edit(e) {
        if (this.params.node.rowPinned) return false;

        this.params.api.startEditingCell({
          rowIndex: this.params.rowIndex,
          colKey: this.params.column.colId
        });
      }
    }
  }
</script>
