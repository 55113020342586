import Controller from "./base_stats_controller"

export default class extends Controller {

  connect() {
    let gridOptions = this.defaultGridOptions();

    gridOptions.columnDefs = [
      {
        field: "category",
        rowGroup: true,
        hide: true
      },
      ...gridOptions.columnDefs
    ];

    this.renderGrid(gridOptions)
  }

}
