import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import ResponseInbox from '../components/ResponseInbox'

export default class extends Controller {
  connect() {
    let url = this.data.get('url');
    let emptyMessage = this.data.get('emptyMessage');

    const vm = new Vue({
      el: this.element,
      template: '<response-inbox :url="url" :empty-message="emptyMessage"></response-inbox>',
      components: {
        ResponseInbox
      },
      beforeMount() {
        this.url = url;
        this.emptyMessage = emptyMessage;
      }
    })
  }
}
