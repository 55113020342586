import Controller from "./base_stats_controller"

export default class extends Controller {

  connect() {
    let gridOptions = this.defaultGridOptions();
    gridOptions.columnDefs = [
      {
        field: "year",
        rowGroup: true,
        hide: true
      },
      ...gridOptions.columnDefs
    ]

    gridOptions.autoGroupColumnDef = {
      ...gridOptions.autoGroupColumnDef,
      flex: null,
      headerName: 'Year / End Date',
      width: 120
    }

    const year = new Date().getFullYear();

    gridOptions.isServerSideGroupOpenByDefault = params => {
      // If the Row Node is not a group, it returns undefined
      if (!params.rowNode.getRoute()) return false;
      return params.rowNode.key === year;
    }

    this.renderGrid(gridOptions)
  }

}
