import { Controller } from "stimulus"
import Vue from 'vue/dist/vue.esm'
import TaskList from '../components/TaskList'

export default class extends Controller {
  connect() {
    let baseUrl = this.data.get('baseUrl');
    let userId = this.data.get('userId');

    const vm = new Vue({
      el: this.element,
      template: '<task-list :user-id="userId" :base-url="baseUrl"></task-list>',
      components: {
        TaskList
      },
      beforeMount() {
        this.baseUrl = baseUrl;
        this.userId = parseInt(userId);
      },
      mounted() {
        $(this.$el).tooltip({
          selector: '.fa-flag'
        });
      }
    })
  }
}
