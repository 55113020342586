// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "./flatpickr_controller";


// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  // all flatpickr hooks are available as callbacks in your Stimulus controller
  close(selectedDates, dateStr, instance) {
    if (document.querySelector('#document_package_allow_responses').checked && this.dueAtFlatpickr.input.value === '') this.dueAtFlatpickr.toggle()
  }

  change(selectedDates, dateStr, instance) {
    this.dueAtFlatpickr.set('minDate', selectedDates[0]);
    if (this.sendReminderAt) this.sendReminderAtFlatpickr.set('minDate', selectedDates[0]);
  }

  get dueAtFlatpickr() {
    return document.querySelector("#document_package_due_at")._flatpickr;
  }

  get sendReminderAt() {
    return document.querySelector("#document_package_send_reminder_at");
  }

  get sendReminderAtFlatpickr() {
    return this.sendReminderAt._flatpickr;
  }
}
