<template>
  <div class="ag-cell-label-container" role="presentation" @click="selectAll" style="cursor: pointer">
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
      <tooltip
        custom-class="tooltip-bold"
        :enterable="false"
        :show-delay="600"
        :enable="params.tooltip !== undefined"
        :text="tooltipText"
      >
        <span ref="eText" class="ag-header-cell-text" role="columnheader">
          {{ displayName }}
        </span>
      </tooltip>
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      displayName() {
        if (this.params.column.colId === 'inputType') return '';
        return this.params.displayName;
      },
      tooltipText() {
        return (this.params.tooltip) ? this.params.tooltip(this.params) : '';
      }
    },
    methods: {
      selectAll(e) {

        const api = this.params.api,
              column = this.params.column,
              lastIndex = api.getDisplayedRowCount() - 1;

        api.clearRangeSelection();
        const topRow = Math.ceil(api.getVerticalPixelRange().top / 40)
        api.setFocusedCell(topRow, column, null);
        api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: lastIndex,
          columns: [column]
        });
      }
    }
  }
</script>
