import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json';
let token = document.getElementsByName('csrf-token')[0];
if (token) {
  axios.defaults.headers.common['X-CSRF-Token'] = token.getAttribute('content');
}


const loginRedirect = function(error) {
  if (!axios.isCancel(error) && error.response && 401 === error.response.status) {
    window.location = '/login'
  }
  return Promise.reject(error)
}

axios.interceptors.request.use(response => response, loginRedirect);
axios.interceptors.response.use(response => response, loginRedirect);

export default axios;
