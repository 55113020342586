import { DirectUpload } from "@rails/activestorage"

export class DirectUploadController {
  constructor(file, attachment) {
    this.directUpload = new DirectUpload(file, '/rails/active_storage/direct_uploads', this)
    this.attachment = attachment
  }

  start(callback) {
    this.directUpload.create((error, attributes) => {
      if (error) {
        console.log('upload failed', error);
        alert('upload failed')
      } else {
        console.log('upload success', this.attachment);
        this.attachment.setAttributes({
          url: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
          href: `/rails/active_storage/blobs/${attributes.signed_id}/${attributes.filename}`,
          image_id: attributes.id
        });
      }

      callback(error)
    })
  }

  uploadRequestDidProgress(event) {
    const progress = event.loaded / event.total * 100
    if (progress) {
      this.attachment.setUploadProgress(progress)
    }
  }

  // DirectUpload delegate

  // directUploadWillCreateBlobWithXHR(xhr) {
  //   this.dispatch("before-blob-request", { xhr })
  // }

  directUploadWillStoreFileWithXHR(xhr) {
    // this.dispatch("before-storage-request", { xhr })
    xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }
}
