import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "terms" ]

  connect() {
    $("#awardee_id").select2();
  }

  togglePrimary(event) {
    this.nameTarget.classList.toggle('hidden', event.target.value == 'true')
  }

  toggleTerms(event) {
    this.termsTarget.classList.toggle('hidden', event.target.value == 'false')
  }

  disconnect() {
    $("#awardee_id").select2("destroy");
  }
}
