import Controller from "./base_stats_controller"

export default class extends Controller {

  connect() {
    let gridOptions = this.defaultGridOptions();
    gridOptions.columnDefs = [
      {
        field: "agency",
        rowGroup: true,
        hide: true
      }, {
        field: "client",
        rowGroup: true,
        hide: true
      },
      ...gridOptions.columnDefs
    ]

    gridOptions.autoGroupColumnDef = {
      ...gridOptions.autoGroupColumnDef,
      flex: 2,
      minWidth: 220,
      headerName: 'Agency / Client / Close Date'
    }

    const agency = parseInt(this.data.get('agency'));
    gridOptions.isServerSideGroupOpenByDefault = params => {
      // Row Node returns undefined if not a group
      if (!agency || !params.rowNode.getRoute() || params.rowNode.level > 0) return false;
      return params.rowNode.data.id === agency;
    }

    this.renderGrid(gridOptions)
  }

  getParams(params) {
    const url = (params.request.groupKeys.length === 2) ? params.parentNode.data.url : '/api/stats/clients';
    const depth = params.request.groupKeys.length;
    let agency;

    if (depth === 1) {
      agency = params.parentNode.data.id;
    } else if (depth === 2) {
      agency = params.parentNode.parent.data.id
    }

    return [url, {
      org: this.data.get('org'),
      agency: agency,
    }]
  }
}
